import { Navigate, Outlet, useRoutes } from 'react-router-dom';

// routes
import {
    IORoute,
    PIRoute,
    SPRoute,
    IGPRoute,
    DGPRoute,
    ACSRoute,
    ProfileRoute,
    ErrorRoute,
    ViewRoute,
    FSL1Route,
    FSL2Route,
    FSL3Route,
    ForensicRoute,
    NCBMalkhanaRoute,
    NCBSeizurRoute,
    AIChatBotRoute
} from './MainRoutes';
import { ADGPRoute, MinisterRoute, JudicialRoute, DIGRoute, SuperUserRoute } from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
    const token = sessionStorage.getItem('token');
    let role = sessionStorage.getItem('role');

    const LoginErrorRoute = { path: '*', element: <Navigate to="/" /> };

    const LoginRoutes = {
        path: '/',
        element: <Outlet />,
        children: [
            { path: '/', element: <Navigate to={`/${role}`} /> },
            { path: '/registerDevice', element: <Navigate to={`/${role}`} /> },
            { path: '/policeLogin', element: <Navigate to={`/${role}`} /> },
            { path: '/policeLogin', element: <Navigate to={`/${role}`} /> },
            { path: '/ministerLogin', element: <Navigate to={`/${role}`} /> },
            { path: '/judicialLogin', element: <Navigate to={`/${role}`} /> },
            { path: '/loginViewUser', element: <Navigate to={`/${role}`} /> },
            { path: '/fslLogin', element: <Navigate to={`/${role}`} /> },
            { path: '/ncbLogin', element: <Navigate to={`/${role}`} /> }
        ]
    };

    let route = [LoginRoutes, AuthenticationRoutes];
    if (token && role?.toLowerCase() === 'io') {
        route = [LoginRoutes, IORoute, ProfileRoute, AIChatBotRoute];
    } else if (token && role?.toLowerCase() === 'pi') {
        route = [LoginRoutes, PIRoute, ProfileRoute];
    } else if (token && (role?.toLowerCase() === 'sp' || role?.toLowerCase() === 'so')) {
        route = [LoginRoutes, SPRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'dig') {
        route = [LoginRoutes, DIGRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'igp') {
        route = [LoginRoutes, IGPRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'dgp') {
        route = [LoginRoutes, DGPRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'adgp') {
        route = [LoginRoutes, ADGPRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'acs') {
        route = [LoginRoutes, ACSRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'minister') {
        route = [LoginRoutes, MinisterRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'judicial') {
        route = [LoginRoutes, JudicialRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'superadmin') {
        route = [LoginRoutes, SuperUserRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'forensic') {
        route = [LoginRoutes, ForensicRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'fsl1') {
        route = [LoginRoutes, FSL1Route, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'fsl2') {
        route = [LoginRoutes, FSL2Route, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'fsl3') {
        route = [LoginRoutes, FSL3Route, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'seizureofficer') {
        route = [LoginRoutes, NCBSeizurRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'malkhanaofficer') {
        route = [LoginRoutes, NCBMalkhanaRoute, ProfileRoute];
    } else if (token && role?.toLowerCase() === 'viewtimeline') {
        route = [LoginRoutes, ViewRoute, ProfileRoute];
    } else {
        route = [AuthenticationRoutes, LoginErrorRoute];
    }
    return useRoutes([...route, ErrorRoute]);
}
